export default {
  baseUrl: '/images/map/',
  noimg: 'no_img.jpg',
  grades: [
    { gid: 'g17', name: 'G17' },
    { gid: 'g15', name: 'G15' },
    { gid: 'g14', name: 'G14' },
    { gid: 'g12', name: 'G12' },
    { gid: 'g10', name: 'G10' },
    { gid: 'g8', name: 'G8' },
  ],
  areas: [
    // G8
    { id: 'b0', name: 'アバラシア雲海', img: 'b0.jpg', grades: ['g8'], xy:[983,177], tile_name: 'the-sea-of-clouds' },
    { id: 'b1', name: 'クルザス西部高地', img: 'b1.jpg', grades: ['g8'], xy:[822,316], tile_name: 'coerthas-western-highlands' },
    { id: 'b2', name: '高地ドラヴァニア', img: 'b2.jpg', grades: ['g8'], xy:[619,246], tile_name: 'the-dravanian-forelands' },
    { id: 'b3', name: 'ドラヴァニア雲海', img: 'b3.jpg', grades: ['g8'], xy:[659,296], tile_name: 'the-churning-mists' },
    // G10
    { id: 'c0', name: 'ギラバニア辺境地帯', img: 'c0.jpg', grades: ['g10'], xy:[1107,303], tile_name: 'the-fringes' },
    { id: 'c1', name: 'ギラバニア山岳地帯', img: 'c1.jpg', grades: ['g10'], xy:[1157,303], tile_name: 'the-peaks' },
    { id: 'c2', name: 'ギラバニア湖畔地帯', img: 'c2.jpg', grades: ['g10'], xy:[1207,303], tile_name: 'the-lochs' },
    { id: 'c3', name: '紅玉海', img: 'c3.jpg', grades: ['g10'], xy:[1714,353], tile_name: 'the-ruby-sea' },
    { id: 'c4', name: 'ヤンサ', img: 'c4.jpg', grades: ['g10'], xy:[1664,353], tile_name: 'yanxia' },
    { id: 'c5', name: 'アジムステップ', img: 'c5.jpg', grades: ['g10'], xy:[1664,303], tile_name: 'the-azim-steppe' },
    //G12
    { id: 'd0', name: 'レイクランド', img: 'd0.jpg', grades: ['g12'], xy:[3773,3459], tile_name: 'lakeland' },
    { id: 'd1', name: 'コルシア島', img: 'd1.jpg', grades: ['g12'], xy:[3509,3561], tile_name: 'kholusia' },
    { id: 'd2', name: 'アム・アレーン', img: 'd2.jpg', grades: ['g12'], xy:[3852,3757], tile_name: 'amh-araeng' },
    { id: 'd3', name: 'イル・メグ', img: 'd3.jpg', grades: ['g12'], xy:[3665,3328], tile_name: 'il-mheg' },
    { id: 'd4', name: 'ラケティカ大森林', img: 'd4.jpg', grades: ['g12'], xy:[3991,3359], tile_name: 'the-rak-tika-greatwood' },
    { id: 'd5', name: 'テンペスト', img: 'd5.jpg', grades: ['g12'], xy:[3698,3540], tile_name: 'the-tempest' },
    //G14
    { id: 'e0', name: 'ラヴィリンソス', img: 'e0.jpg', grades: ['g14'], xy:[3773,3459], tile_name: 'labyrinthos' },
    { id: 'e1', name: 'サベネア島', img: 'e1.jpg', grades: ['g14'], xy:[3509,3561],  tile_name: 'thavnair' },
    { id: 'e2', name: 'ガレマルド', img: 'e2.jpg', grades: ['g14'], xy:[3852,3757], tile_name: 'garlemald' },
    { id: 'e3', name: '嘆きの海', img: 'e3.jpg', grades: ['g14'], xy:[3665,3328], tile_name: 'mare-lamentorum' },
    { id: 'e5', name: 'ウルティマ・トゥーレ', img: 'e5.jpg', grades: ['g14'], xy:[3698,3540], tile_name: 'ultima-thule' },
    // G15
    { id: 'e4', name: 'エルピス', img: 'e4.jpg', grades: ['g15'], xy:[3991,3359], tile_name: 'elpis' },
    // G17
    { id: 'f1', name: 'オルコ・パチャ', grades: ['g17'], xy:[191,159], tile_name: 'urqopacha' },
    { id: 'f2', name: 'コザマル・カ', grades: ['g17'], xy:[91,59], tile_name: 'kozama-uka' },
    { id: 'f3', name: 'ヤクテル樹海', grades: ['g17'], xy:[-191,259], tile_name: 'yak-tel' },
    { id: 'f4', name: 'シャーローニ荒野', grades: ['g17'], xy:[691,259], tile_name: 'shaaloani' },
    { id: 'f5', name: 'ヘリテージファウンド', grades: ['g17'], xy:[991,159], tile_name: 'heritage-found' },
    { id: 'f6', name: 'リビング・メモリー', grades: ['g18'], xy:[1091,209], tile_name: 'living-memory' },
  ],
  locations: [
    { gid:'g8', aid:'b0', name: 'A', id: 'b0a', img: 'a.jpg', xy: [6.6, 7.2]}, // アバラシア雲海 ( 6.6  , 7.2 ) Z:1.5
    { gid:'g8', aid:'b0', name: 'B', id: 'b0b', img: 'b.jpg', xy: [20.6, 5.3]}, // アバラシア雲海 ( 20.6  , 5.3 ) Z:2.5
    { gid:'g8', aid:'b0', name: 'C', id: 'b0c', img: 'c.jpg', xy: [22.8, 10.7]}, // アバラシア雲海 ( 22.8  , 10.7 ) Z:2.5
    { gid:'g8', aid:'b0', name: 'D', id: 'b0d', img: 'd.jpg', xy: [17, 17]},
    { gid:'g8', aid:'b0', name: 'E', id: 'b0e', img: 'e.jpg', xy: [31, 18]},
    { gid:'g8', aid:'b0', name: 'F', id: 'b0f', img: 'f.jpg', xy: [10.4, 39.1]}, // アバラシア雲海 ( 10.4  , 39.1 ) Z:0.1
    { gid:'g8', aid:'b0', name: 'G', id: 'b0g', img: 'g.jpg', xy: [14.4, 26.9]}, // アバラシア雲海 ( 14.4  , 26.9 ) Z:0.8
    { gid:'g8', aid:'b0', name: 'H', id: 'b0h', img: 'h.jpg', xy: [18.8, 34.9]}, // アバラシア雲海 ( 18.8  , 34.9 ) Z:0.7
    { gid:'g8', aid:'b0', name: 'I', id: 'b0i', img: 'i.jpg', xy: [21, 31]},
    { gid:'g8', aid:'b0', name: 'J', id: 'b0j', img: 'j.jpg', xy: [29.1, 24.1]}, // アバラシア雲海 ( 29.1  , 24.1 ) Z:3.4
    { gid:'g8', aid:'b1', name: 'A', id: 'b1a', img: 'a.jpg', xy: [7, 12]},
    { gid:'g8', aid:'b1', name: 'B', id: 'b1b', img: 'b.jpg', xy: [21, 12]},
    { gid:'g8', aid:'b1', name: 'C', id: 'b1c', img: 'c.jpg', xy: [12, 24]},
    { gid:'g8', aid:'b1', name: 'D', id: 'b1d', img: 'd.jpg', xy: [20.2, 30.9]}, // クルザス西部高地 ( 20.2  , 30.9 ) Z:0.5
    { gid:'g8', aid:'b1', name: 'E', id: 'b1e', img: 'e.jpg', xy: [34.8, 7.7]}, // クルザス西部高地 ( 34.8  , 7.7 ) Z:0.7
    { gid:'g8', aid:'b1', name: 'F', id: 'b1f', img: 'f.jpg', xy: [29.1, 15.5]}, // クルザス西部高地 ( 29.1  , 15.5 ) Z:0.9
    { gid:'g8', aid:'b1', name: 'G', id: 'b1g', img: 'g.jpg', xy: [36, 26]},
    { gid:'g8', aid:'b2', name: 'A', id: 'b2a', img: 'a.jpg', xy: [10.6, 30.1]}, // 高地ドラヴァニア ( 10.6  , 30.1 ) Z:0.8
    { gid:'g8', aid:'b2', name: 'B', id: 'b2b', img: 'b.jpg', xy: [23.5, 25.5]},
    { gid:'g8', aid:'b2', name: 'C', id: 'b2c', img: 'c.jpg', xy: [29.0, 24.0]}, // 高地ドラヴァニア ( 29.0  , 24.0 ) Z:0.9
    { gid:'g8', aid:'b2', name: 'D', id: 'b2d', img: 'd.jpg', xy: [12.0, 35.0]}, // 高地ドラヴァニア ( 12.0  , 35.0 ) Z:0.9
    { gid:'g8', aid:'b2', name: 'E', id: 'b2e', img: 'e.jpg', xy: [13.4, 34.6]}, // 高地ドラヴァニア ( 13.4  , 34.6 ) Z:0.7
    { gid:'g8', aid:'b2', name: 'F', id: 'b2f', img: 'f.jpg', xy: [14.9, 34.4]}, // 高地ドラヴァニア ( 14.9  , 34.4 ) Z:1.2
    { gid:'g8', aid:'b2', name: 'G', id: 'b2g', img: 'g.jpg', xy: [18.8, 34.1]}, // 高地ドラヴァニア ( 18.8  , 34.1 ) Z:0.9
    { gid:'g8', aid:'b2', name: 'H', id: 'b2h', img: 'h.jpg', xy: [24, 32]},
    { gid:'g8', aid:'b2', name: 'I', id: 'b2i', img: 'i.jpg', xy: [27, 30]},
    { gid:'g8', aid:'b3', name: 'A', id: 'b3a', img: 'a.jpg', xy: [10.3, 9.6]}, // ドラヴァニア雲海 ( 10.3  , 9.6 ) Z:3.3
    { gid:'g8', aid:'b3', name: 'B', id: 'b3b', img: 'b.jpg', xy: [12.8, 13.2]},
    { gid:'g8', aid:'b3', name: 'C', id: 'b3c', img: 'c.jpg', xy: [7.9, 17.6]},  // ドラヴァニア雲海 ( 7.9  , 17.6 ) Z:3.3
    { gid:'g8', aid:'b3', name: 'D', id: 'b3d', img: 'd.jpg', xy: [10.6, 20.3]},
    { gid:'g8', aid:'b3', name: 'E', id: 'b3e', img: 'e.jpg', xy: [23.2, 19.2]},
    { gid:'g8', aid:'b3', name: 'F', id: 'b3f', img: 'f.jpg', xy: [32.1, 25.6]}, // ラヴァニア雲海 ( 32.1  , 25.6 ) Z:0.5
    { gid:'g8', aid:'b3', name: 'G', id: 'b3g', img: 'g.jpg', xy: [17.3, 33.7]}, // ドラヴァニア雲海 ( 17.3  , 33.7 ) Z:1.1
    { gid:'g8', aid:'b3', name: 'H', id: 'b3h', img: 'h.jpg', xy: [21.6, 37.8]}, // ドラヴァニア雲海 ( 21.6  , 37.8 ) Z:0.1
    { gid:'g8', aid:'b3', name: 'I', id: 'b3i', img: 'i.jpg', xy: [28.0, 37.5]}, // ドラヴァニア雲海 ( 28.0  , 37.5 ) Z:0.9
    { gid:'g10', aid:'c0', name: 'A', id: 'c0a', img: '', xy: [15.5, 16.5]},
    { gid:'g10', aid:'c0', name: 'B', id: 'c0b', img: '', xy: [9.3, 12.5]},
    { gid:'g10', aid:'c0', name: 'C', id: 'c0c', img: 'c.jpg', xy: [11.1, 31.1]},
    { gid:'g10', aid:'c0', name: 'D', id: 'c0d', img: '', xy: [31.5, 20.8]},
    { gid:'g10', aid:'c0', name: 'E', id: 'c0e', img: '', xy: [8.3, 28.2]},
    { gid:'g10', aid:'c0', name: 'F', id: 'c0f', img: '', xy: [25.7, 20.1]},
    { gid:'g10', aid:'c0', name: 'G', id: 'c0g', img: 'g.jpg', xy: [23.4, 12.2]},
    { gid:'g10', aid:'c0', name: 'H', id: 'c0h', img: '', xy: [33.0, 31.4]},
    { gid:'g10', aid:'c1', name: 'A', id: 'c1a', img: '', xy: [8.6, 32.5]},
    { gid:'g10', aid:'c1', name: 'B', id: 'c1b', img: '', xy: [16.1, 9.2]},
    { gid:'g10', aid:'c1', name: 'C', id: 'c1c', img: '', xy: [24.0, 31.6]},
    { gid:'g10', aid:'c1', name: 'D', id: 'c1d', img: '', xy: [15.7, 33.1]},
    { gid:'g10', aid:'c1', name: 'E', id: 'c1e', img: '', xy: [15.4, 22.9]},
    { gid:'g10', aid:'c1', name: 'F', id: 'c1f', img: '', xy: [26.0, 13.7]},
    { gid:'g10', aid:'c1', name: 'G', id: 'c1g', img: '', xy: [12.2, 5.5]},
    { gid:'g10', aid:'c1', name: 'H', id: 'c1h', img: '', xy: [33.1, 9.8]},
    { gid:'g10', aid:'c2', name: 'A', id: 'c2a', img: '', xy: [8.6, 17.7]},
    { gid:'g10', aid:'c2', name: 'B', id: 'c2b', img: '', xy: [5.8, 6.7]},
    { gid:'g10', aid:'c2', name: 'C', id: 'c2c', img: '', xy: [31.9, 6.4]},
    { gid:'g10', aid:'c2', name: 'D', id: 'c2d', img: '', xy: [25.1, 7.8]},
    { gid:'g10', aid:'c2', name: 'E', id: 'c2e', img: '', xy: [19.6, 23.3]},
    { gid:'g10', aid:'c2', name: 'F', id: 'c2f', img: '', xy: [15.9, 28.6]},
    { gid:'g10', aid:'c2', name: 'G', id: 'c2g', img: '', xy: [24.5, 32.0]},
    { gid:'g10', aid:'c2', name: 'H', id: 'c2h', img: '', xy: [29.7, 17.4]},
    { gid:'g10', aid:'c3', name: 'A', id: 'c3a', img: '', xy: [7.7, 29.7]},
    { gid:'g10', aid:'c3', name: 'B', id: 'c3b', img: '', xy: [5.2, 15.1]},
    { gid:'g10', aid:'c3', name: 'C', id: 'c3c', img: 'c.jpg', xy: [32.9, 18.4]},
    { gid:'g10', aid:'c3', name: 'D', id: 'c3d', img: '', xy: [27.7, 30.0]},
    { gid:'g10', aid:'c3', name: 'E', id: 'c3e', img: '', xy: [30.5, 25.4]},
    { gid:'g10', aid:'c3', name: 'F', id: 'c3f', img: '', xy: [16.9, 9.6]},
    { gid:'g10', aid:'c3', name: 'G', id: 'c3g', img: '', xy: [18.4, 36.3]},
    { gid:'g10', aid:'c3', name: 'H', id: 'c3h', img: '', xy: [32.7, 8.8]},
    { gid:'g10', aid:'c4', name: 'A', id: 'c4a', img: '', xy: [31.0, 28.5]},
    { gid:'g10', aid:'c4', name: 'B', id: 'c4b', img: '', xy: [13.2, 30.1]},
    { gid:'g10', aid:'c4', name: 'C', id: 'c4c', img: 'c.jpg', xy: [31.8, 35.5]},
    { gid:'g10', aid:'c4', name: 'D', id: 'c4d', img: '', xy: [22.2, 27.8]},
    { gid:'g10', aid:'c4', name: 'E', id: 'c4e', img: '', xy: [32.0, 5.4]},
    { gid:'g10', aid:'c4', name: 'F', id: 'c4f', img: 'f.jpg', xy: [20.0, 5.5]},
    { gid:'g10', aid:'c4', name: 'G', id: 'c4g', img: '', xy: [21.8, 13.9]},
    { gid:'g10', aid:'c4', name: 'H', id: 'c4h', img: '', xy: [12.0, 18.9]},
    { gid:'g10', aid:'c5', name: 'A', id: 'c5a', img: '', xy: [4.9, 25.5]},
    { gid:'g10', aid:'c5', name: 'B', id: 'c5a', img: '', xy: [21.7, 36.9]},
    { gid:'g10', aid:'c5', name: 'C', id: 'c5a', img: '', xy: [30.9, 22.1]},
    { gid:'g10', aid:'c5', name: 'D', id: 'c5a', img: '', xy: [29.2, 35.3]},
    { gid:'g10', aid:'c5', name: 'E', id: 'c5a', img: '', xy: [29.5, 11.8]},
    { gid:'g10', aid:'c5', name: 'F', id: 'c5a', img: '', xy: [16.5, 25.0]},
    { gid:'g10', aid:'c5', name: 'G', id: 'c5a', img: '', xy: [14.9, 33.0]},
    { gid:'g10', aid:'c5', name: 'H', id: 'c5a', img: '', xy: [10.6, 15.9]},
    { gid:'g12', aid:'d0', name: 'A', id: 'd0a', img: '', xy: [18.2, 7.6, 1.0]},
    { gid:'g12', aid:'d0', name: 'B', id: 'd0b', img: '', xy: [10.7, 11.7, 0.7]},
    { gid:'g12', aid:'d0', name: 'C', id: 'd0c', img: '', xy: [13.7, 12.8, 0.9]},
    { gid:'g12', aid:'d0', name: 'D', id: 'd0d', img: '', xy: [17.9, 17.0, 0.1]},
    { gid:'g12', aid:'d0', name: 'E', id: 'd0e', img: '', xy: [8.3, 21.0, 0.6]},
    { gid:'g12', aid:'d0', name: 'F', id: 'd0f', img: '', xy: [10.5, 25.3, 0.0]},
    { gid:'g12', aid:'d0', name: 'G', id: 'd0g', img: '', xy: [38.2, 13.8, 0.8]},
    { gid:'g12', aid:'d0', name: 'H', id: 'd0h', img: '', xy: [34.7, 25.7, 0.1]},
    { gid:'g12', aid:'d1', name: 'A', id: 'd1a', img: '', xy: [34.8, 10.7, 2.7]},
    { gid:'g12', aid:'d1', name: 'B', id: 'd1b', img: '', xy: [30.8, 17.2, 3.0]},
    { gid:'g12', aid:'d1', name: 'C', id: 'd1c', img: '', xy: [20.5, 9.2, 4.2]},
    { gid:'g12', aid:'d1', name: 'D', id: 'd1d', img: '', xy: [20.0, 16.9, 3.4]},
    { gid:'g12', aid:'d1', name: 'E', id: 'd1e', img: '', xy: [13.5, 16.7, 3.5]},
    { gid:'g12', aid:'d1', name: 'F', id: 'd1f', img: '', xy: [11.9, 13.8, 3.7]},
    { gid:'g12', aid:'d1', name: 'G', id: 'd1g', img: '', xy: [7.7, 18.0, 3.5]},
    { gid:'g12', aid:'d1', name: 'H', id: 'd1h', img: '', xy: [33.2, 31.4, 0.0]},
    { gid:'g12', aid:'d2', name: 'A', id: 'd2a', img: '', xy: [12.3, 14.2, 1.1]},
    { gid:'g12', aid:'d2', name: 'B', id: 'd2b', img: '', xy: [13.2, 30.0, 0.5]},
    { gid:'g12', aid:'d2', name: 'C', id: 'd2c', img: '', xy: [32.4, 8.6, 0.6]},
    { gid:'g12', aid:'d2', name: 'D', id: 'd2d', img: '', xy: [36.1, 11.5, 0.4]},
    { gid:'g12', aid:'d2', name: 'E', id: 'd2e', img: '', xy: [27.6, 12.8, 0.8]},
    { gid:'g12', aid:'d2', name: 'F', id: 'd2f', img: '', xy: [34.0, 17.2, 0.8]},
    { gid:'g12', aid:'d2', name: 'G', id: 'd2g', img: '', xy: [26.8, 23.6, 0.7]},
    { gid:'g12', aid:'d2', name: 'H', id: 'd2h', img: '', xy: [30.2, 30.7, 0.5]},
    { gid:'g12', aid:'d3', name: 'A', id: 'd3a', img: '', xy: [33.2, 10.4, 0.9]},
    { gid:'g12', aid:'d3', name: 'B', id: 'd3b', img: '', xy: [31.0, 4.0, 1.0]},
    { gid:'g12', aid:'d3', name: 'C', id: 'd3c', img: '', xy: [25.1, 12.4, 0.0]},
    { gid:'g12', aid:'d3', name: 'D', id: 'd3d', img: '', xy: [21.7, 7.6, 0.5]},
    { gid:'g12', aid:'d3', name: 'E', id: 'd3e', img: '', xy: [10.5, 13.1, 0.7]},
    { gid:'g12', aid:'d3', name: 'F', id: 'd3f', img: '', xy: [7.3, 17.4, 0.3]},
    { gid:'g12', aid:'d3', name: 'G', id: 'd3g', img: 'g.jpg', xy: [12.9, 20.6, 0.0]},
    { gid:'g12', aid:'d3', name: 'H', id: 'd3h', img: '', xy: [14.2, 27.5, 0.2]},
    { gid:'g12', aid:'d4', name: 'A', id: 'd4a', img: '', xy: [34.4, 17.0, -0.5]},
    { gid:'g12', aid:'d4', name: 'B', id: 'd4b', img: '', xy: [35.3, 22.4, 0.1]},
    { gid:'g12', aid:'d4', name: 'C', id: 'd4c', img: '', xy: [24.5, 15.3, -0.1]},
    { gid:'g12', aid:'d4', name: 'D', id: 'd4d', img: '', xy: [24.9, 27.7, -0.1]},
    { gid:'g12', aid:'d4', name: 'E', id: 'd4e', img: '', xy: [22.6, 32.4, -0.3]},
    { gid:'g12', aid:'d4', name: 'F', id: 'd4f', img: '', xy: [26.1, 34.7, -0.3]},
    { gid:'g12', aid:'d4', name: 'G', id: 'd4g', img: '', xy: [11.6, 19.5, 0.1]},
    { gid:'g12', aid:'d4', name: 'H', id: 'd4h', img: '', xy: [13.1, 23.9, 0.0]},
    { gid:'g12', aid:'d5', name: 'A', id: 'd5a', img: '', xy: [37.3, 17.7, -1.4]},
    { gid:'g12', aid:'d5', name: 'B', id: 'd5b', img: '', xy: [32.6, 5.1, -1.1]},
    { gid:'g12', aid:'d5', name: 'C', id: 'd5c', img: '', xy: [30.1, 20.9, -2.0]},
    { gid:'g12', aid:'d5', name: 'D', id: 'd5d', img: '', xy: [25.4, 11.4, -1.9]},
    { gid:'g12', aid:'d5', name: 'E', id: 'd5e', img: '', xy: [19.0, 8.2, -1.4]},
    { gid:'g12', aid:'d5', name: 'F', id: 'd5f', img: '', xy: [12.6, 11.4, -3.7]},
    { gid:'g12', aid:'d5', name: 'G', id: 'd5g', img: '', xy: [13.8, 14.9, -4.3]},
    { gid:'g12', aid:'d5', name: 'H', id: 'd5h', img: 'h.jpg', xy: [16.2, 18.5, -4.3]},
    { gid:'g14', aid:'e0', name: 'A', id: 'e0a', img: '', xy: [21.8, 37.5, 2.1]},
    { gid:'g14', aid:'e0', name: 'B', id: 'e0b', img: '', xy: [18.6, 18.1, 2.0]},
    { gid:'g14', aid:'e0', name: 'C', id: 'e0c', img: '', xy: [6.8, 20.9, 2.2]},
    { gid:'g14', aid:'e0', name: 'D', id: 'e0d', img: '', xy: [24.2, 10.9, 3.0]},
    { gid:'g14', aid:'e0', name: 'E', id: 'e0e', img: '', xy: [31.8, 14.4, 3.8]},
    { gid:'g14', aid:'e0', name: 'F', id: 'e0f', img: '', xy: [8.4, 30.4, 1.9]},
    { gid:'g14', aid:'e0', name: 'G', id: 'e0g', img: '', xy: [24.9, 21.8, 2.0]},
    { gid:'g14', aid:'e0', name: 'H', id: 'e0h', img: '', xy: [25.0, 30.1, 1.0]},
    { gid:'g14', aid:'e1', name: 'A', id: 'e1a', img: '', xy: [19.5, 14.2, 0.3]},
    { gid:'g14', aid:'e1', name: 'B', id: 'e1b', img: '', xy: [14.8, 26.6, 0.0]},
    { gid:'g14', aid:'e1', name: 'C', id: 'e1c', img: '', xy: [26.8, 28.9, 0.0]},
    { gid:'g14', aid:'e1', name: 'D', id: 'e1d', img: '', xy: [21.9, 21.0, 0.2]},
    { gid:'g14', aid:'e1', name: 'E', id: 'e1e', img: '', xy: [27.1, 8.9, 0.0]},
    { gid:'g14', aid:'e1', name: 'F', id: 'e1f', img: '', xy: [14.7, 7.9, 0.5]},
    { gid:'g14', aid:'e1', name: 'G', id: 'e1g', img: '', xy: [21.0, 27.2, 0.5]},
    { gid:'g14', aid:'e1', name: 'H', id: 'e1h', img: '', xy: [32.2, 14.4, 0.1]},
    { gid:'g14', aid:'e2', name: 'A', id: 'e2a', img: '', xy: [11.8, 25.7, 0.6]},
    { gid:'g14', aid:'e2', name: 'B', id: 'e2b', img: '', xy: [28.1, 26.4, 0.1]},
    { gid:'g14', aid:'e2', name: 'C', id: 'e2c', img: '', xy: [29.3, 35.4, 0.0]},
    { gid:'g14', aid:'e2', name: 'D', id: 'e2d', img: '', xy: [25.3, 12.4, 0.4]},
    { gid:'g14', aid:'e2', name: 'E', id: 'e2e', img: '', xy: [16.1, 17.8, 0.3]},
    { gid:'g14', aid:'e2', name: 'F', id: 'e2f', img: '', xy: [34.2, 9.9, 0.3]},
    { gid:'g14', aid:'e2', name: 'G', id: 'e2g', img: '', xy: [16.6, 11.9, 0.3]},
    { gid:'g14', aid:'e2', name: 'H', id: 'e2h', img: '', xy: [34.4, 17.3, 0.3]},
    { gid:'g14', aid:'e3', name: 'A', id: 'e3a', img: '', xy: [15.9, 34.4, 1.1]},
    { gid:'g14', aid:'e3', name: 'B', id: 'e3b', img: '', xy: [17.1, 19.4, 1.2]},
    { gid:'g14', aid:'e3', name: 'C', id: 'e3c', img: '', xy: [30.5, 24.9, 1.0]},
    { gid:'g14', aid:'e3', name: 'D', id: 'e3d', img: '', xy: [12.2, 26.6, 1.2]},
    { gid:'g14', aid:'e3', name: 'E', id: 'e3e', img: '', xy: [25.6, 33.3, 0.8]},
    { gid:'g14', aid:'e3', name: 'F', id: 'e3f', img: '', xy: [22.8, 36.3, 1.1]},
    { gid:'g14', aid:'e3', name: 'G', id: 'e3g', img: '', xy: [18.7, 25.7, 0.6]},
    { gid:'g14', aid:'e3', name: 'H', id: 'e3h', img: '', xy: [34.8, 29.9, 1.4]},
    { gid:'g14', aid:'e5', name: 'A', id: 'e5a', img: '', xy: [29.6, 9.4, 2.4]},
    { gid:'g14', aid:'e5', name: 'B', id: 'e5b', img: '', xy: [25.7, 17.2, 1.9]},
    { gid:'g14', aid:'e5', name: 'C', id: 'e5c', img: '', xy: [8.1, 30.7, 0.3]},
    { gid:'g14', aid:'e5', name: 'D', id: 'e5d', img: '', xy: [12.7, 13.4, 2.2]},
    { gid:'g14', aid:'e5', name: 'E', id: 'e5e', img: '', xy: [26.7, 35.5, 3.4]},
    { gid:'g14', aid:'e5', name: 'F', id: 'e5f', img: '', xy: [21.4, 36.3, 0.1]},
    { gid:'g14', aid:'e5', name: 'G', id: 'e5g', img: '', xy: [17.9, 27.0, 0.2]},
    { gid:'g14', aid:'e5', name: 'H', id: 'e5h', img: '', xy: [6.1, 19.0, 0.2]},
    { gid:'g15', aid:'e4', name: 'A', id: 'e4a', img: '', xy: [11.8, 33.1, 1.4]},
    { gid:'g15', aid:'e4', name: 'B', id: 'e4b', img: '', xy: [16.8, 31.0, 1.7]},
    { gid:'g15', aid:'e4', name: 'C', id: 'e4c', img: '', xy: [22.5, 24.6, 1.7]},
    { gid:'g15', aid:'e4', name: 'D', id: 'e4d', img: '', xy: [27.1, 24.3, 1.7]},
    { gid:'g15', aid:'e4', name: 'E', id: 'e4e', img: '', xy: [37.2, 18.7, 3.1]},
    { gid:'g15', aid:'e4', name: 'F', id: 'e4f', img: '', xy: [29.0, 17.5, 3.1]},
    { gid:'g15', aid:'e4', name: 'G', id: 'e4g', img: '', xy: [29.9, 9.5, 3.1]},
    { gid:'g15', aid:'e4', name: 'H', id: 'e4h', img: '', xy: [13.0, 8.7, 4.6]},
    { gid:'g17', aid:'f1', name: 'A', id: 'f1a', img: '', xy: [13.6, 14.1, 1.7]},
    { gid:'g17', aid:'f1', name: 'B', id: 'f1b', img: '', xy: [34.2, 29.0, 3.4]},
    { gid:'g17', aid:'f1', name: 'C', id: 'f1c', img: '', xy: [34.2, 32.6, 3.3]},
    { gid:'g17', aid:'f1', name: 'D', id: 'f1d', img: '', xy: [28.9, 24.0, 2.8]},
    { gid:'g17', aid:'f1', name: 'E', id: 'f1e', img: '', xy: [21.6, 23.8, 2.4]},
    { gid:'g17', aid:'f1', name: 'F', id: 'f1f', img: '', xy: [21.7, 26.0, 2.4]},
    { gid:'g17', aid:'f1', name: 'G', id: 'f1g', img: '', xy: [16.9, 23.4, 2.8]},
    { gid:'g17', aid:'f1', name: 'H', id: 'f1h', img: '', xy: [15.7, 30.6, 2.9]},
    { gid:'g17', aid:'f2', name: 'A', id: 'f2a', img: '', xy: [36.9, 6.2, 0.4]},
    { gid:'g17', aid:'f2', name: 'B', id: 'f2b', img: '', xy: [19.9, 6.9, 0.2]},
    { gid:'g17', aid:'f2', name: 'C', id: 'f2c', img: '', xy: [31.4, 14.9, 0.1]},
    { gid:'g17', aid:'f2', name: 'D', id: 'f2d', img: '', xy: [37.9, 21.7, 0.2]},
    { gid:'g17', aid:'f2', name: 'E', id: 'f2e', img: '', xy: [18.9, 22.5, 1.1]},
    { gid:'g17', aid:'f2', name: 'F', id: 'f2f', img: '', xy: [6.9, 19.4, 0.6]},
    { gid:'g17', aid:'f2', name: 'G', id: 'f2g', img: '', xy: [32.2, 36.1, 1.2]},
    { gid:'g17', aid:'f2', name: 'H', id: 'f2h', img: '', xy: [9.0, 33.8, 1.3]},
    { gid:'g17', aid:'f3', name: 'A', id: 'f3a', img: '', xy: [22.6, 5.8, 3.0]},
    { gid:'g17', aid:'f3', name: 'B', id: 'f3b', img: '', xy: [36.6, 12.5, 3.3]},
    { gid:'g17', aid:'f3', name: 'C', id: 'f3c', img: '', xy: [31.1, 21.9, 3.1]},
    { gid:'g17', aid:'f3', name: 'D', id: 'f3d', img: '', xy: [24.7, 20.9, 1.4]},
    { gid:'g17', aid:'f3', name: 'E', id: 'f3e', img: '', xy: [5.6, 19.6, 3.3]},
    { gid:'g17', aid:'f3', name: 'F', id: 'f3f', img: '', xy: [22.7, 35.8, 1.3]},
    { gid:'g17', aid:'f3', name: 'G', id: 'f3g', img: '', xy: [15.4, 26.8, 1.3]},
    { gid:'g17', aid:'f3', name: 'H', id: 'f3h', img: '', xy: [11.2, 33.2, 1.4]},
    { gid:'g17', aid:'f4', name: 'A', id: 'f4a', img: '', xy: [29.3, 8.4, 0.5]},
    { gid:'g17', aid:'f4', name: 'B', id: 'f4b', img: '', xy: [18.5, 11.6, 0.9]},
    { gid:'g17', aid:'f4', name: 'C', id: 'f4c', img: '', xy: [12.5, 14.4, 1.0]},
    { gid:'g17', aid:'f4', name: 'D', id: 'f4d', img: '', xy: [35.5, 16.9, 0.6]},
    { gid:'g17', aid:'f4', name: 'E', id: 'f4e', img: '', xy: [25.6, 19.7, 0.7]},
    { gid:'g17', aid:'f4', name: 'F', id: 'f4f', img: '', xy: [18.2, 21.0, 0.9]},
    { gid:'g17', aid:'f4', name: 'G', id: 'f4g', img: '', xy: [31.9, 32.9, 0.9]},
    { gid:'g17', aid:'f4', name: 'H', id: 'f4h', img: '', xy: [19.7, 34.5, 0.5]},
    { gid:'g17', aid:'f5', name: 'A', id: 'f5a', img: '', xy: [31.1, 8.4, 1.4]},
    { gid:'g17', aid:'f5', name: 'B', id: 'f5b', img: '', xy: [16.6, 13.0, 0.3]},
    { gid:'g17', aid:'f5', name: 'C', id: 'f5c', img: '', xy: [22.1, 19.1, 1.0]},
    { gid:'g17', aid:'f5', name: 'D', id: 'f5d', img: '', xy: [17.2, 22.2, 0.4]},
    { gid:'g17', aid:'f5', name: 'E', id: 'f5e', img: '', xy: [9.0, 21.2, 0.3]},
    { gid:'g17', aid:'f5', name: 'F', id: 'f5f', img: '', xy: [35.8, 25.5, 1.6]},
    { gid:'g17', aid:'f5', name: 'G', id: 'f5g', img: '', xy: [26.2, 30.8, 1.3]},
    { gid:'g17', aid:'f5', name: 'H', id: 'f5h', img: '', xy: [16.8, 35.8, 0.0]},
  ],
  aliases: [
    {id: 'b0', aliase: 'アバラシア雲海'},
    {id: 'b0', aliase: 'アバラシア'},
    {id: 'b0', aliase: 'アバ雲', recomend:true},
    {id: 'b1', aliase: 'クルザス西部高地'},
    {id: 'b1', aliase: 'クルザス西部'},
    {id: 'b1', aliase: '西部', recomend:true},
    {id: 'b2', aliase: '高地ドラヴァニア'},
    {id: 'b2', aliase: '高地ドラ'},
    {id: 'b2', aliase: '高地', recomend:true },
    {id: 'b2', aliase: 'ドラ高地'},
    {id: 'b3', aliase: 'ドラヴァニア雲海'},
    {id: 'b3', aliase: 'ドラ雲海'},
    {id: 'b3', aliase: 'ドラ雲', recomend:true},
    {id: 'c0', aliase: 'ギラバニア辺境地帯'},
    {id: 'c0', aliase: '辺境地帯'},
    {id: 'c0', aliase: '辺境', recomend:true},
    {id: 'c1', aliase: 'ギラバニア山岳地帯'},
    {id: 'c1', aliase: '山岳地帯'},
    {id: 'c1', aliase: '山岳', recomend:true},
    {id: 'c2', aliase: 'ギラバニア湖畔地帯'},
    {id: 'c2', aliase: '湖畔地帯'},
    {id: 'c2', aliase: '湖畔', recomend:true},
    {id: 'c3', aliase: '紅玉海'},
    {id: 'c3', aliase: '紅玉' , recomend:true},
    {id: 'c4', aliase: 'ヤンサ', recomend:true},
    {id: 'c5', aliase: 'アジムステップ'},
    {id: 'c5', aliase: 'アジム', recomend:true},
    {id: 'c5', aliase: '安心院'},
    {id: 'd0', aliase: 'レイクランド'},
    {id: 'd0', aliase: 'レイク', recomend:true},
    {id: 'd1', aliase: 'コルシア島'},
    {id: 'd1', aliase: 'コルシア', recomend:true},
    {id: 'd2', aliase: 'アム・アレーン'},
    {id: 'd2', aliase: 'アムアレーン'},
    {id: 'd2', aliase: 'アム', recomend:true},
    {id: 'd3', aliase: 'イル・メグ'},
    {id: 'd3', aliase: 'イルメグ' },
    {id: 'd3', aliase: 'メグ', recomend:true},
    {id: 'd4', aliase: 'ラケティカ大森林'},
    {id: 'd4', aliase: 'ラケティカ'},
    {id: 'd4', aliase: 'ラヒ', recomend:true},
    {id: 'd5', aliase: 'テンペスト'},
    {id: 'd5', aliase: 'テンペ', recomend:true},
    {id: 'e0', aliase: 'ラヴィリンソス', recomend:true},
    {id: 'e0', aliase: 'ラヴィ'},
    {id: 'e1', aliase: 'サベネア島', recomend:true},
    {id: 'e1', aliase: 'サベネア'},
    {id: 'e2', aliase: 'ガレマルド', recomend:true},
    {id: 'e3', aliase: '嘆きの海', recomend:true},
    {id: 'e3', aliase: '月'},
    {id: 'e4', aliase: 'エルピス', recomend:true},
    {id: 'e5', aliase: 'ウルティマ・トゥーレ', recomend:true},
    {id: 'e5', aliase: 'ウルティマ'},
    {id: 'f1', aliase: 'オルコ・パチャ', recomend:true},
    {id: 'f2', aliase: 'コザマル・カ', recomend:true},
    {id: 'f3', aliase: 'ヤクテル樹海', recomend:true},
    {id: 'f4', aliase: 'シャーローニ荒野', recomend:true},
    {id: 'f5', aliase: 'ヘリテージファウンド', recomend:true},
    {id: 'f6', aliase: 'リビング・メモリー', recomend:true},
  ],
  aetherytes: [
    { name: 'キャンプ・クラウドトップ', areaId: 'b0', xy: [10.2, 33.6], weight: 7},
    { name: 'オク・ズンド', areaId: 'b0', xy: [10.4, 14.5], weight: 7},
    { name: 'ファルコンネスト', areaId: 'b1', xy: [31.9, 36.8], weight: 3},
    { name: 'テイルフェザーからクルザス西部へ', areaId: 'b1', xy: [5.6, 9.7], weight: 14},
    { name: 'テイルフェザー', areaId: 'b2', xy: [33.1, 23.3], weight: 3},
    { name: '不浄の三塔', areaId: 'b2', xy: [16.5, 23.5], weight: 3},
    { name: 'モグモグホーム', areaId: 'b3', xy: [27.7, 34.2], weight: 8},
    { name: '白亜の宮殿', areaId: 'b3', xy: [10.7, 29.0], weight: 3},
    { name: 'カストルム・オリエンス', areaId: 'c0', xy: [8.8, 11.3], weight: 7},
    { name: 'ピーリングストーンズ', areaId: 'c0', xy: [29.7, 26.3], weight: 7},
    { name: 'アラガーナ', areaId: 'c1', xy: [23.8, 6.5], weight: 7},
    { name: 'アラギリ', areaId: 'c1', xy: [16.0, 36.4], weight: 7},
    { name: 'ポルタ・プレトリア', areaId: 'c2', xy: [8.3, 21.1], weight: 7},
    { name: 'アラミガン・クォーター', areaId: 'c2', xy: [33.7, 34.6], weight: 7},
    { name: 'オノコロ島', areaId: 'c3', xy: [23.2, 9.7], weight: 7},
    { name: '碧のタマミズ', areaId: 'c3', xy: [28.5, 16.1], weight: 20},
    { name: 'クガネより紅玉海方面', areaId: 'c3', xy: [38.3, 38.3], weight: 15},
    { name: 'ナマイ村', areaId: 'c4', xy: [30.1, 19.7], weight: 7},
    { name: '烈士庵', areaId: 'c4', xy: [26.4, 13.3], weight: 8},
    { name: '再会の市', areaId: 'c5', xy: [32.6, 28.2], weight: 7},
    { name: '明けの玉座', areaId: 'c5', xy: [23.0, 22.1], weight: 7},
    { name: 'ドーロ・イロー', areaId: 'c5', xy: [6.3, 23.8], weight: 7},
    { name: 'ジョッブ砦', areaId: 'd0', xy: [36.5, 20.8, 0.2], weight: 7},
    { name: 'オスタル厳命城', areaId: 'd0', xy: [6.8, 16.8], weight: 7},
    { name: 'スティルタイド', areaId: 'd1', xy: [34.8, 27.3, 0.2], weight: 7},
    { name: 'ライト村', areaId: 'd1', xy: [16.5, 29.1, 0.1], weight: 7},
    { name: 'トメラの村', areaId: 'd1', xy: [12.9, 8.9, 4.1], weight: 7},
    { name: 'モルドスーク', areaId: 'd2', xy: [26.4, 17.0, 1.2], weight: 7},
    { name: '旅立ちの宿', areaId: 'd2', xy: [29.4, 27.6, 0.9], weight: 7},
    { name: 'トゥワイン', areaId: 'd2', xy: [11.1, 17.2, 1.6], weight: 7},
    { name: 'リダ・ラーン', areaId: 'd3', xy: [14.5, 31.7, 0.4], weight: 7},
    { name: 'プラ・エンニ茸窟', areaId: 'd3', xy: [20.0, 4.2, 1.0], weight: 7},
    { name: 'ヴォレクドルフ', areaId: 'd3', xy: [29.0, 7.7, 0.8], weight: 7},
    { name: 'スリザーバウ', areaId: 'd4', xy: [19.3, 27.4, 0.2], weight: 7},
    { name: 'ファノヴの里', areaId: 'd4', xy: [29.1, 17.6, 0.2], weight: 7},
    { name: 'オンドの潮溜まり', areaId: 'd5', xy: [32.7, 17.5, -2.0], weight: 7},
   // { name: 'マカレンサス広場', areaId: 'd5', xy: [18.5, 25.8, -8.7], weight: 25},
    { name: 'アルケイオン保管院', areaId: 'e0', xy: [30.3, 11.9, 3.9], weight: 7},
    { name: 'リトルシャーレアン', areaId: 'e0', xy: [21.6, 20.5, 1.9], weight: 7},
    { name: 'アポリア本部', areaId: 'e0', xy: [6.9, 27.5, 1.9], weight: 7},
    { name: 'イェドリマン', areaId: 'e1', xy: [25.4, 34.0, 0.0], weight: 7},
    { name: 'デミールの遺烈郷', areaId: 'e1', xy: [11.0, 22.2, 0.0], weight: 7},
    { name: 'パーラカの里', areaId: 'e1', xy: [29.5, 16.5, 0.0], weight: 7},
    { name: 'キャンプ・ブロークングラス', areaId: 'e2', xy: [13.3, 31.1, 0.4], weight: 7},
    { name: 'テルティウム駅', areaId: 'e2', xy: [31.9, 17.8, -0.2], weight: 7},
    { name: '涙の入江', areaId: 'e3', xy: [10.0, 34.3, 1.3], weight: 7},
    { name: 'ベストウェイ・バロー', areaId: 'e3', xy: [21.4, 11.1, -1.4], weight: 12},
    { name: 'アナグノリシス天測園', areaId: 'e4', xy: [24.7, 24.0, 1.7], weight: 7},
    { name: '十二節の園', areaId: 'e4', xy: [8.8, 32.3, 1.4], weight: 7},
    { name: 'ポイエテーン・オイコス', areaId: 'e4', xy: [21.4, 11.1, -1.4], weight: 7},
    { name: 'リア・ターラ', areaId: 'e5', xy: [ 10.6, 26.8, 0.2], weight: 7},
    { name: 'イーアの里', areaId: 'e5', xy: [22.8, 8.3, 2.2], weight: 12},
    { name: 'オミクロンベース', areaId: 'e5', xy: [31.2, 28.1, 3.9], weight: 7},
    { name: 'ワチュン・ペロ', areaId: 'f1', xy: [28.1, 13.1, 0.6], weight: 7},
    { name: 'ウォーラーの残響', areaId: 'f1', xy: [30.8, 34.1, 3.3], weight: 7},
    { name: 'オック・ハヌ', areaId: 'f2', xy: [18.1, 11.8, 0.0], weight: 7},
    { name: '朋友の灯火', areaId: 'f2', xy: [32.3, 25.5, 1.1], weight: 7},
    { name: 'アースンシャイア', areaId: 'f2', xy: [11.9, 27.7, 1.2], weight: 7},
    { name: 'イクブラーシャ', areaId: 'f3', xy: [13.5, 12.8, 3.2], weight: 7},
    { name: 'マムーク', areaId: 'f3', xy: [35.8, 32.0, 1.6], weight: 7},
    { name: 'フーサタイ宿場街', areaId: 'f4', xy: [29.2, 30.8, 0.7], weight: 7},
    { name: 'シュシュネ青燐泉', areaId: 'f4', xy: [15.6, 19.2, 0.9], weight: 7},
    { name: 'メワヘイゾーン', areaId: 'f4', xy: [27.7, 10.1, 0.6], weight: 7},
    { name: 'ヤースラニ駅', areaId: 'f5', xy: [31.8, 25.6, 1.6], weight: 7},
    { name: 'アウトスカーツ', areaId: 'f5', xy: [16.9, 9.7, 0.4], weight: 7},
    { name: 'エレクトロープ採掘場', areaId: 'f5', xy: [17.0, 23.9, 0.4], weight: 7},
    { name: 'レイノード・メモリス', areaId: 'f6', xy: [21.4, 37.4, 0.7], weight: 7},
    { name: 'レイノード・ファイア', areaId: 'f6', xy: [34.6, 15.8, 0.4], weight: 7},
    { name: 'レイノード・ウィンド', areaId: 'f6', xy: [16.3, 13.5, 0.7], weight: 7},
  ]
}
